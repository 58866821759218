import React from 'react';
import { Link } from 'gatsby';

import Layout from 'components/Layout';
import Seo from 'components/Seo';

export default function PageChangelog() {
  return (
    <Layout>
      <Seo title="Changelog" />
      <h1>CHANGELOG</h1>
      <h2>
        19w05 — <small>First adjustments </small>
      </h2>
      <ul>
        <li>Rewrite of all components into functional components.</li>
        <li>
          Create own folders for components and integrate them correct into page
          components.
        </li>
        <li>
          Added util folder with two hooks as starter (you might need to
          switch/update React to use them).
        </li>
        <li>Added Emotion.js including GlobalStyles component.</li>
        <li>
          Added various components such as Null, Logo, Icon and Logo component.
        </li>
        <li>Add env file with typekit key as first variable.</li>
        <li>Reset of README.md</li>
        <li>Adjustment of descriptions within package.json.</li>
        <li>Eslint installation and setup.</li>
        <li>
          Addition of first Babel feature and packages such as:
          @babel/plugin-proposal-optional-chaining
        </li>
        <li>
          Reset of yarn lock file since someone used npm initially{' '}
          <span aria-label="puking emoji" role="img">
            🤮
          </span>
        </li>
        <li>
          Added Gatsby Plugin webfontloader including proxima nova as new
          default font
        </li>
        <li>
          Added webpack config additions for relative paths
          <code>components/...</code> and
          <code>utils/...</code> etc.
        </li>
        <li>
          Added placeholder logo including as first icon component. So new svg
          icons are easy to integrate now.
        </li>
        <li>General cleanup of codebase</li>
        <li>
          Version numbers will be [year]w[week][a, b, c, ...] => 19w05, 19w05a,
          19w05b, 19w06, …
        </li>
      </ul>
      <h2>
        19w04 — <small>Initial Release</small>
      </h2>
      <ul>
        <li>Using Gatsby Starter</li>
        <li>Agile Announcement</li>
        <li>Changelog</li>
      </ul>
      <br />
      <br />
      <Link to="/">Go back to the homepage</Link>
    </Layout>
  );
}
