import React from 'react';
import PropTypes from 'prop-types';

import Null from 'components/Null';

import { IconStyled } from './IconStyled';
import * as IconMap from './IconMap';

export default function Icon({
  type,
  width,
  height,
  viewBox,
  preserveAspectRatio,
  // For theming purposes ~DH
  // fillPrimary,
  // fillSecondary,
  // fillTertiary,
  // currentColor,
  className,
  title,
}) {
  const iconMap = {
    logoDunckelfeld: {
      icon: IconMap.LogoDunckelfeld,
      props: {
        viewBox: '0 0 796.5 153.4',
        width: '345',
        height: '75',
      },
    },
  };

  // Avoid obsolete nesting within iconMap if there are no settings ~DH
  const IconContent = iconMap?.[type]?.icon || iconMap?.[type];

  return IconContent ? (
    <IconStyled
      xmlns="http://www.w3.org/2000/svg"
      data-icon={type} // For debugging purposes ~DH
      width={width || iconMap?.[type]?.props?.width || '24px'}
      height={height || iconMap?.[type]?.props?.height || '24px'}
      viewBox={viewBox || iconMap?.[type]?.props?.viewBox || '0 0 24 24'}
      preserveAspectRatio={preserveAspectRatio}
      className={className}
      title={title}
    >
      <IconContent
      // For theming purposes ~DH
      // fillPrimary={currentColor ? 'currentColor' : fillPrimary}
      // fillSecondary={currentColor ? 'currentColor' : fillSecondary}
      // fillTertiary={fillTertiary}
      />
    </IconStyled>
  ) : (
    // Fallback if the iconMap doesnt match right so that the app does not crash ~DH
    <Null
      warning={console.warn(`Error occured while loading your Icon:'${type}'`)}
    />
  );
}

/* eslint-disable react/require-default-props */
// We set the default values/props for height, width and viewbox within component as fallback ~DH
Icon.propTypes = {
  type: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  viewBox: PropTypes.string,
  preserveAspectRatio: PropTypes.string,
  title: PropTypes.string,
  // currentColor: PropTypes.bool,
  // For theming purposes ~DH
  // fillPrimary: PropTypes.string,
  // fillSecondary: PropTypes.string,
  // fillTertiary: PropTypes.string,
};

Icon.defaultProps = {
  type: 'arrowLeft',
  preserveAspectRatio: 'xMidYMid meet',
  // currentColor: true,
  // For theming purposes ~DH
  // fillPrimary: colorPrimary,
  // fillSecondary: colorSecondary,
  // fillTertiary: colorTertiary,
};
