import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';

import { LogoStyled } from './LogoStyled';

export default function Logo({ title }) {
  return (
    <LogoStyled>
      <Icon type="logoDunckelfeld" title={title} />
    </LogoStyled>
  );
}

Logo.propTypes = {
  title: PropTypes.string,
};

Logo.defaultProps = {
  title: 'Logo title',
};
