import styled from '@emotion/styled';

export const ContentStyled = styled('div')({});

export const LayoutStyled = styled('div')({
  margin: '0 auto',
  maxWidth: 960,
  padding: '0px 1.0875rem 1.45rem',
  paddingTop: 0,
});
