import React from 'react';
import { Global } from '@emotion/core';

export default function GlobalStyles() {
  return (
    <Global
      styles={{
        '*': {
          margin: 0,
          padding: 0,
        },

        html: {
          fontFamily: 'proxima-nova, sans-serif',
          fontSize: '18px',
          lineHeight: 1.5,
        },

        'h1, h2': {
          letterSpacing: '0.03em',
          lineHeight: '1.25',
        },

        h1: {
          fontSize: '3em',
        },

        h2: {
          fontSize: '1.6em',
          marginTop: '2rem',
        },

        a: {
          color: 'hotpink',
          textDecoration: 'none',
        },

        'a:hover': {
          color: '#009688',
          textDecoration: 'underline',
        },

        p: {
          margin: 0,
        },

        code: {
          background: 'lightgrey',
          border: '1px solid darkgrey',
          padding: '3px 4px',
          margin: '0 12px',
          borderRadius: '4px',
          fontSize: '13px',
          lineHeight: 1,
        },

        // no scroll class for sticky plugin ~DH
        '.no-scroll': {
          overflow: 'hidden !important',
          position: 'fixed',
          width: '100%',
        },

        // to avoid FOAT of typekit/webfontloader loaded fonts ~DH
        '.wf-loading': {
          visibility: 'hidden',
        },
      }}
    />
  );
}
