import React from 'react';

import { FooterStyled } from './FooterStyled';

export default function Footer() {
  return (
    <FooterStyled>
      DUNCKELFELD {new Date().getFullYear()} | WIP since 2011
    </FooterStyled>
  );
}
