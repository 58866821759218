import PropTypes from 'prop-types';
import React from 'react';

import Logo from 'components/Logo';

export default function Header({ siteTitle }) {
  return <Logo title={siteTitle} />;
}

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};
